import React, { useEffect, useState } from 'react'
import Link from '../utils/link'
import { Logo, PrevArrow } from './icons'

const Header = ({ backgroundColor, data }) => {

  const [offCanvas, setOffCanvas] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const loggedIn = typeof localStorage !== 'undefined' && !!localStorage.getItem('password')

  const scrollToView = (link) => {
    let section = document.getElementById(link.replace('#', ''))
    section && section.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  useEffect(() => {
    let lastScrollTop = 0
    const handleScroll = () => {
      if (window.scrollY > 60 && window.scrollY > lastScrollTop && (window.innerHeight + Math.round(window.scrollY)) < document.body.offsetHeight) {
        // check scroll direction
        setScrolled(true)
      } else {
        setScrolled(false)
      }
      lastScrollTop = window.scrollY
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <header className={`header ${scrolled ? 'header--scrolled' : ''}`} style={{ backgroundColor: backgroundColor }}>
        <div className='header__inner'>
          <div className='header__logo'>
            <Link to={'/home/'}>
              <Logo />
            </Link>
          </div>

          <div className='header__menu'>
            <button onClick={() => setOffCanvas(true)} className='header__menu-btn'>
              Menu
            </button>

            <Link to='/register/' className='header__register'>
              Register
            </Link>

            <Link to='/home/' className='header__explore'>
              <PrevArrow />
              Explore<span> Rokeby</span>
            </Link>
          </div>
        </div>
      </header>
      <div className='header__padding' />
      <div className={`header__off-canvas ${offCanvas ? 'active' : ''}`}>
        <div className='header__off-canvas-inner'>
          <div className='header__off-canvas-header'>
            <div className='header__logo'>
              <Link to={'/'}>
                <Logo />
              </Link>
            </div>

            <div className='header__menu'>
              <button onClick={() => setOffCanvas(false)} className='header__close'>
                Close
              </button>
            </div>
          </div>

          <div className='header__off-canvas-body'>
            <div />
            <div className='header__off-canvas-menu'>
              {data.headerMenu?.nodes.map((item, index) => (
                <Link key={index} to={item.uri} activeClassName='active' className='header__off-canvas-menu-link' onClick={() => setOffCanvas(false)}>
                  {item.label}
                </Link>
              ))}
            </div>
          </div>

          <div className='header__off-canvas-footer'>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header