import React from 'react'
import Link from '../utils/link'
import { Logo, Arup, Carr, Figurehead } from './icons'

const Footer = ({ backgroundColor, data }) => {
  return (
    <footer className='footer' style={{ backgroundColor }}>
      <div className='footer__inner'>
        <div className='footer__content'>
          <Link to='/' title='Site Name' className='footer__logo'>
            <Logo />
          </Link>
          <p>
            The Wurundjeri Woi Wurrung are the Traditional Owners of the land at Rokeby Street, it is a place of significance for the broader Aboriginal community. Figurehead respect and honour Aboriginal and Torres Strait Islander Elders past, present and future. We acknowledge the stories, traditions and living First Nations cultures and commit to building a better, more sustainable future together.
          </p>
          <ul className='footer__brand'>
            <li><Figurehead /></li>
            <li><Carr /></li>
            <li><Arup /></li>
          </ul>
        </div>
        <div className='footer__menu'>
          <ul>
            {data.footerMenu?.nodes.slice(0, 10).map((item, index) => (
              <li key={index}>
                <Link to={item.uri} activeClassName='active' className='footer__menu-link'>
                  {item.label}
                </Link>
              </li>
            ))}
            { data.footerMenu?.nodes.length < 10 && (
              new Array(10 - data.footerMenu?.nodes.length).fill(0).map((_, index) => (<li key={index} />))
            )}
            <li className='long-link'>
              Follow 116 Rokeby on <Link to='https://www.instagram.com/116rokeby/'>Instagram</Link>
            </li>
            <li className='long-link'>
              A project by <Link to='https://www.figurehead.com.au/'>Figurehead</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
